var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"mt-2"},[(_vm.data && _vm.data.FinanceValues && _vm.data.FinanceValues.length)?_c('v-row',[_c('v-col',[_c('tfoms-bar',{attrs:{"title":"Выполнение плана","data":_vm.getValues(
            _vm.data.FinanceValues,
            'FinancialPlanValue',
            'FinancialValue'
          ),"icon":"mdi-currency-rub","labels":_vm.labels,"sub-title":_vm.subTitlePeriod,"factColor":"#0CA85D","values":[
          {
            label: this.provideUnits.rub.text,
          },
        ]}})],1),_c('v-col',[_c('tfoms-lost-income',{attrs:{"plan":_vm.data.FinanceTotalPlanValue,"fact":_vm.data.FinanceTotalValue,"percent":_vm.data.FinancePercent}})],1)],1):_vm._e(),(_vm.data && _vm.data.Children)?_c('tfoms-condition-table',{staticClass:"mt-5",attrs:{"data":_vm.data.Children.map((e) => ({
        plan: e.FinancialPlanValue,
        fact: e.FinancialValue,
        percent: e.FinancialValuePercent,
        Name: e.Name,
        id: e.Id,
        Codes: e.Codes,
      })),"labels":_vm.labels,"subTitle":_vm.subTitlePeriod,"color":"success","hide-icon":"","values":[
      {
        label: this.provideUnits.rub.text,
      },
    ]}}):_vm._e(),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-partition-table',{attrs:{"data":_vm.data.Divisions,"labels":_vm.labels,"type":4,"hide-icon":"","sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.rub.text,
          },
        ]},model:{value:(_vm.selectEmployee),callback:function ($$v) {_vm.selectEmployee=$$v},expression:"selectEmployee"}}):_vm._e()],1),_c('v-col',[(_vm.data && _vm.data.Divisions)?_c('tfoms-employee-table',{attrs:{"selectPartiton":_vm.selectEmployee,"data":_vm.data.Divisions,"labels":_vm.labels,"type":4,"hide-icon":"","items":_vm.data.Employees,"sub-title":_vm.subTitlePeriod,"values":[
          {
            label: this.provideUnits.rub.text,
          },
        ]}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }